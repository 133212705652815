<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>

      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-large-container">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-form 
              :model="form" 
              id="form"
              lazy-validation
              @submit.prevent="submitForm()"
              autocomplete="off"
              method="post"
              accept-charset="UTF-8" 
              enctype="multipart/form-data"
            >
              <div>
                <v-alert v-if="form && !form.status"
                  text
                  type="info"
                  class="mb-4"
                >
                  <h3>{{ $t('verify_business_alert') }}</h3>
                  {{ $t('msg_verify_business_alert') }}
                </v-alert>
                <v-alert v-if="form && form.status == 'success'"
                  text
                  type="success"
                  class="mb-4"
                >
                  <h3>{{ $t('verify_business_success') }}</h3>
                  {{ $t('msg_verify_business_success') }}
                </v-alert>
                <v-alert v-if="form && form.status == 'pending'"
                  text
                  type="warning"
                  class="mb-4"
                >
                  <h3>{{ $t('verify_business_pending') }}</h3>
                  {{ $t('msg_verify_business_pending') }}
                </v-alert>
                <v-alert v-if="form && form.status == 'unapprove'"
                  text
                  type="error"
                  class="mb-4"
                >
                  <h3>{{ $t('verify_business_unapprove') }}</h3>
                  <div class="pre-line">{{ form.note_unapprove }}</div>
                </v-alert>

                <div v-if="!view"> 
                  <x-select
                    :items="item_type_business"
                    v-model="form.type_business"
                    item-text="name"
                    item-value="value"
                    :label="$t('type_business')"
                    filled
                    rules="required"
                  ></x-select>
                </div>
                <div class="view_div border" v-if="view">
                  <div class="text-body-1">{{$t('type_business')}}</div>
                  <div class="text-h6">{{form.text_type_business}}</div>
                </div>

                <div v-if="form.type_business == 'company' || 
                form.type_business == 'registered' ||
                form.type_business == 'unregistered' ||
                form.type_business == 'proprietorship'">
                  <div v-if="!view"> 
                    <p>{{ $t('msg_business_name_by_law') }}</p>
                    <x-text-field
                      rules="required"
                      :label="$t('business_name_by_law')"
                      v-model="form.business_name_by_law"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('business_name_by_law')}}</div>
                    <div class="text-h6">{{form.business_name_by_law}}</div>
                  </div>
                </div>
                
                <div>
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required"
                      :label="$t('legal_name')"
                      v-model="form.legal_name"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('legal_name')}}</div>
                    <div class="text-h6">{{form.legal_name}}</div>
                  </div>
                </div>

                <div> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required|integer"
                      :label="$t('national_id')"
                      v-model="form.national_id"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('national_id')}}</div>
                    <div class="text-h6">{{form.national_id}}</div>
                  </div>
                </div>

                <div>
                  <div v-if="!view"> 
                    <x-autocomplete
                      v-model="form.currency"
                      ref="form.currency"
                      id="form.currency"
                      :items="currencies"
                      item-value="0" 
                      item-text="1"
                      :label="$t('currency')"
                      :name="$t('currency')"
                      rules="required"
                      :hint="$t('business_currency_hint')"
                      persistent-hint
                    />
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('currency')}}</div>
                    <div class="text-h6">{{ currencies.find((e)=> e[0] == form.currency) ? currencies.find((e)=> e[0] == form.currency)[1] : form.currency }}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' ||
                form.type_business == 'registered'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required|integer"
                      :label="$t('tax_number')"
                      v-model="form.tax_number"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('tax_number')}}</div>
                    <div class="text-h6">{{form.tax_number}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' ||
                form.type_business == 'registered'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required"
                      :label="$t('company_registration_number')"
                      v-model="form.company_registration_number"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('company_registration_number')}}</div>
                    <div class="text-h6">{{form.company_registration_number}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' || 
                form.type_business == 'registered' ||
                form.type_business == 'unregistered' ||
                form.type_business == 'proprietorship'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      :label="$t('business_operating_name') + $t('_optional_')"
                      v-model="form.business_operating_name"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view && form.business_operating_name">
                    <div class="text-body-1">{{$t('business_operating_name')}}</div>
                    <div class="text-h6">{{form.business_operating_name}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'individual'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required|email"
                      :label="$t('business_email')"
                      v-model="form.business_email"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('business_email')}}</div>
                    <div class="text-h6">{{form.business_email}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'individual'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required"
                      :label="$t('position')"
                      v-model="form.position"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('position')}}</div>
                    <div class="text-h6">{{form.position}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'individual'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required"
                      :label="$t('nationality')"
                      v-model="form.nationality"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('nationality')}}</div>
                    <div class="text-h6">{{form.nationality}}</div>
                  </div>
                </div>

                <div v-if="form.type_business == 'individual'"> 
                  <div v-if="!view"> 
                    <x-date-picker
                      v-model="form.birthday"
                      :label="$t('birthday')"
                      prepend-inner-icon="mdi-timer"
                      locale="th"
                      _format="YYYY-MM-DD"
                      :persistent-hint="true"
                      filled
                      rules="required"
                    />
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('birthday')}}</div>
                    <div class="text-h6">{{form.birthday}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' || 
                form.type_business == 'individual' ||
                form.type_business == 'registered' ||
                form.type_business == 'unregistered'"> 
                  <div v-if="!view"> 
                    <x-text-area
                      rules="required"
                      v-model="form.business_address"
                      :label="$t('business_address')"
                      filled
                    ></x-text-area>
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('business_address')}}</div>
                    <div class="text-h6">{{form.business_address}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' || 
                form.type_business == 'registered' ||
                form.type_business == 'unregistered' ||
                form.type_business == 'individual'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required|integer"
                      :label="$t('business_phone_number')"
                      v-model="form.business_phone_number"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('business_phone_number')}}</div>
                    <div class="text-h6">{{form.business_phone_number}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' ||
                form.type_business == 'registered' ||
                form.type_business == 'unregistered' ||
                form.type_business == 'proprietorship'"> 
                  <div v-if="!view"> 
                    <x-autocomplete
                      v-model="form.industry"
                      :items="item_industry"
                      item-text="name"
                      item-value="value"
                      chips
                      :label="$t('industry')"
                      multiple
                      filled
                    ></x-autocomplete>
                    <x-text-field
                      :label="$t('more_industry')"
                      v-model="form.more_industry"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('industry')}}</div>
                    <div class="text-h6" v-if="form.industry_text">{{form.industry_text}}</div>
                    <div class="text-h6" v-if="form.more_industry">{{form.more_industry}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' || 
                form.type_business == 'registered' ||
                form.type_business == 'unregistered' ||
                form.type_business == 'proprietorship'"> 
                  <div v-if="!view"> 
                    <x-text-field
                      rules="required"
                      :label="$t('business_website')"
                      v-model="form.business_website"
                      filled
                    ></x-text-field> 
                  </div>
                  <div class="view_div border" v-if="view">
                    <div class="text-body-1">{{$t('business_website')}}</div>
                    <div class="text-h6">{{form.business_website}}</div>
                  </div>
                </div>
                
                <div v-if="form.type_business == 'company' ||
                form.type_business == 'registered' ||
                form.type_business == 'unregistered' ||
                form.type_business == 'proprietorship'"> 
                  <div v-if="!view"> 
                    <x-text-area
                      v-model="form.business_description"
                      :label="$t('business_description') + $t('_optional_')"
                      filled
                    ></x-text-area>
                  </div>
                  <div class="view_div border" v-if="view && form.business_description">
                    <div class="text-body-1">{{$t('business_description')}}</div>
                    <div class="text-h6">{{form.business_description}}</div>
                  </div>
                </div>

                <div :class="view ? 'view_div' : ''" 
                v-if="form.type_business == 'company' ||
                form.type_business == 'registered'"> 
                  <v-label v-if="!view" class="input-label mb-2">{{$t('img_registration_number')}}</v-label>
                  <div v-else class="text-body-1 mb-2">{{$t('img_registration_number')}}</div>
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <!-- :size="!view ? 150 : '100%'" -->
                      <v-avatar
                        width="100%"
                        :height="!view ? 250 : '100%'"
                        class="input-upload"
                      >
                        <v-img :src="form.img_certificate" class=""  contain 
                        :style="{'width': '100%','max-width': '100%'}" >
                          <v-fade-transition v-if="!view">
                            <v-overlay
                              v-if="hover"
                              absolute
                            >
                              <v-btn @click="uploadImg('certificate')" small fab color="primary"><v-icon>mdi-folder-open-outline</v-icon></v-btn>
                              <v-btn v-if="form.upload_certificate && form.upload_certificate_editor" @click="EditImage('certificate')" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                              <v-btn v-if="form.img_certificate" @click="form.img_certificate=null; form.upload_certificate=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon>mdi-close</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </v-avatar>
                    </template>
                  </v-hover>

                  <input
                    type="file"
                    style="display: none"
                    id="certificate"
                    name="certificate"
                    ref="fileupload"
                    accept="image/*"
                    @change="onFilePickedCertificate"
                  >
                </div>

                <div :class="view ? 'view_div' : 'mt-4'"> 
                  <v-label v-if="!view" class="input-label mb-2">{{$t('img_national_id')}}</v-label>
                  <div v-else class="text-body-1 mb-2">{{$t('img_national_id')}}</div>
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <!-- :size="!view ? 150 : '100%'" -->
                      <v-avatar
                        width="100%"
                        :height="!view ? 250 : '100%'"
                        class="input-upload">
                        <v-img :src="form.img_national_id" class=""  contain 
                        :style="{'width': '100%','max-width': '100%'}" >
                          <v-fade-transition v-if="!view">
                            <v-overlay
                              v-if="hover"
                              absolute
                            >
                              <v-btn @click="uploadImg('national_id')" small fab color="primary"><v-icon>mdi-folder-open-outline</v-icon></v-btn>
                              <v-btn v-if="form.upload_national_id && form.upload_national_id_editor" @click="EditImage('national_id')" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                              <v-btn v-if="form.img_national_id" @click="form.img_national_id=null; form.upload_national_id=null;" class="ml-2" small fab color="error"><v-icon>mdi-close</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </v-avatar>
                    </template>
                  </v-hover>
                 
                  <input
                    type="file"
                    style="display: none"
                    id="national_id"
                    name="national_id"
                    accept="image/*"
                    @change="onFilePickedNationalID"
                  >
                </div>
              </div>
              <div class="d-flex mt-8" v-if="!view">
                <v-btn color="primary" block  rounded depressed x-large :loading="loading" :disabled="loading || invalid" type="submit" class="no-caps">
                  {{ $t('save') }} <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-form>
          </ValidationObserver>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>

import item_industry from '@/components/json/industry.json'
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'
export default {
  components: {
    //MenuBusinessSetting
  },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    currencies: [],
    loading: false,
    view: false,
    business: null,
    item_type_business: null,
    item_industry: item_industry,
    loading_main: true,
    form: {
      type_business: 'company',
      currency: 'THB',
      img_certificate: null,
      upload_certificate: null,
      img_national_id: null,
      upload_national_id: null,
      industry: []
    },
    myValue: '',
    myOptions: [
      {id: 1, text: 'test1'}, 
      {id: 2, text: 'test2'}
    ],
  }),
  created () {
    // Set locale
    //console.log(this.item_industry)
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    // Tab images
    this.tabImgs = [
      this.$init.config.schemeAndHost + '/img/business-transactions-tab.jpg'
    ]

    // Set image depending on the size of the browser window
    if (window.innerWidth > 960) {
      this.tabImg = this.tabImgs[0]
      this.tabImgHeight = this.tabImgDefaultHeight
      this.tabImgAspectRation = 1.7778
    } else {
      this.tabImgHeight = null
      this.tabImgAspectRation = 3
    }

    // Preload
    let images = new Array()
    this.tabImgs.forEach(function(img, i) {
      images[i] = new Image()
      images[i].src = img
    })

    this.axios
    .get('/localization/currencies', { params: { locale: this.$i18n.locale }})
    .then(response => {
      this.currencies = this.$_.toPairs(response.data)
      //console.log(this.currencies)
    })

    this.getData()
  },
  methods: {
    getData (){
      this.loading_main = true
      this.axios
      .get('/business/advance-settings', { 
        params: { locale: this.$i18n.locale }
      })
      .then(response => {
        this.loading_main = false
        this.business = response.data.business
        this.item_type_business = response.data.type_business
        if(response.data.verify_business){
          this.form = response.data.verify_business
          //console.log(this.form.industry)
          if(this.form.industry){
            let industry = JSON.parse(this.form.industry)
            //console.log(industry)
            this.form.industry = industry
          }
          if(response.data.verify_business.status == 'pending' || 
          response.data.verify_business.status == 'success'){
            this.view = true
            if(this.form.type_business){
              let data_type_business = this.item_type_business.find((item)=> item.value == this.form.type_business)
              this.form.text_type_business = data_type_business.name
            }
            //console.log(this.form.industry)
            if(this.form.industry.length){
              let industry = this.form.industry
              let text = ''
              let c = 0
              for(let e of industry){
                c++
                let data_industry = this.item_industry.find((item)=> item.value == e)
                //console.log(data_industry)
                //console.log(c)
                text += data_industry.name + (c < industry.length ? ',':'')
              }
              this.form.industry_text = text
            }
          }
        }
      })
    },
    async submitForm (){
      this.$root.$confirm(this.$t('confirm_update_verify_business'), this.$t('msg_confirm_update_verify_business'))
        .then((confirm) => {
          if (confirm.confirm) {
            this.confirmSubmitForm()
          }
        })
    },
    confirmSubmitForm (){
      //console.log(this.form)
      if((this.form.type_business == 'company' || this.form.type_business == 'registered') &&
      !this.form.img_certificate){
        this.$root.$snackbar(this.$t('enter_img_registration_number'))
        return false
      }
      if(!this.form.img_national_id){
        this.$root.$snackbar(this.$t('enter_img_national_id'))
        return false
      }

      if(this.form.type_business != 'individual' && !this.form.more_industry && !this.form.industry.length){
        this.$root.$snackbar(this.$t('enter_industry'))
        return false
      } 
      this.loading = true
      let settings = { headers: { 'content-type': 'multipart/form-data' } }
      let formData = new FormData()
      this.form.upload_certificate = this.form.upload_certificate_editor
      this.form.upload_national_id = this.form.upload_national_id_editor
      for(let i in this.form){
        //console.log(i)
        //console.log(this.form[i])
        formData.append(i, this.form[i])
      }
      //console.log(formData)
      this.axios.post('/business/update-verify-business', formData, settings)
      .then(res => {
        if (res.data.status === 'success') {
          this.$root.$snackbar(this.$t('update_verify_business_success'))
          this.getData()
        }
      })
      .catch(err => {
        this.loading = false
        // if (err.response.data.status === 'error') {
        //   this.$root.$snackbar(err.response.data.error)
        // }
        if (err.response.data.status === 'error') {
          let errors = err.response.data.errors || []
          for (let field in errors) {
            this.$root.$snackbar(errors[field])
          }
          if(err.response.data.error){
            this.$root.$snackbar(err.response.data.error)
          }
        }
      })
      .finally(() => {
        this.loading = false
      })
      
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    uploadImg (id) {
      document.getElementById(id).click()
    },
    onFilePickedCertificate (e) {
      const files = e.target.files
      //console.log(files)
      if(files[0] !== undefined) {
        if(files[0].name.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.img_certificate = fr.result
          this.form.upload_certificate = files[0]
          this.form.upload_certificate_editor = files[0]
          this.$root.$ImageEditor(files[0],'certificate')
          .then((confirm) => {
            if (confirm.dest) {
              this.form['img_' + confirm.optional] = URL.createObjectURL(confirm.dest);
              this.form['upload_' + confirm.optional +'_editor'] = confirm.dest
            }
          })
          //console.log(this.upload_cover)
        })
      }
    },
    onFilePickedNationalID (e) {
      const files = e.target.files
      //console.log(files)
      if(files[0] !== undefined) {
        if(files[0].name.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.img_national_id = fr.result
          this.form.upload_national_id = files[0]
          this.form.upload_national_id_editor = files[0]
          this.$root.$ImageEditor(files[0],'national_id')
          .then((confirm) => {
            if (confirm.dest) {
              this.form['img_' + confirm.optional] = URL.createObjectURL(confirm.dest);
              this.form['upload_' + confirm.optional +'_editor'] = confirm.dest
            }
          })
          //console.log(this.upload_cover)
        })
      }
    },
    EditImage(id){
      this.$root.$ImageEditor(this.form['upload_' + confirm.optional],id)
      .then((confirm) => {
        if (confirm.dest) {
          this.form['img_' + confirm.optional] = URL.createObjectURL(confirm.dest);
          this.form['upload_' + confirm.optional +'_editor'] = confirm.dest
        }
      })
    },
  }
}
</script>
