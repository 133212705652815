<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>

      <div v-if="!loading_main">
        <v-row no-gutters>
          <v-col 
            v-if="subscriptionSettings"
            cols="12"
            class="mb-4 mb-sm-8"
          >
            <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
              <!-- <div class="content-large-container max-width-lg-100"> -->
              <div class="max-width-lg-100">
                <div v-if="package_now">
                  <div class="text-h6 mb-4">{{ $t('package') }} : {{ package_now.name }}</div>
                  <div class="d-flex align-center">
                    <div class="text-subtitle-1">{{ $t('verify_slip_quantity') }}</div>
                    <div class="text-h6 ml-4"><span :class="font_color">{{ verify_slip_used }}</span> / <span class="">{{ verify_slip }}</span></div>
                  </div>
                  <p class="text-body-1 mb-0" v-if="(package_now.id != 1)">{{ $t('premium_subscription_end_date', { expires_date: formatDate(subscriptionSettings.subscription.expired_at, 'LL'), expires_friendly: formatDate(subscriptionSettings.subscription.expired_at, 'DD/MM/YYYY')}) }}</p>
                </div>

                <div v-if="package_next">
                  <div class="text-h6 mb-4">{{ $t('next_package') }} : {{ package_next.name }}</div>
                  <p class="text-body-1 mb-0" v-if="(package_next.id != 1)">{{ $t('premium_subscription_start_date', { expires_date: formatDate(subscriptionSettings.subscription.expired_at, 'LL'), expires_friendly: formatDate(subscriptionSettings.subscription.expired_at, 'DD/MM/YYYY')}) }}</p>
                  <p class="text-body-1 mb-0" v-if="(package_next.id != 1)">{{ $t('premium_subscription_end_date', { expires_date: formatDate(subscriptionSettings.pending_subscription.expired_at, 'LL'), expires_friendly: formatDate(subscriptionSettings.pending_subscription.expired_at, 'DD/MM/YYYY')}) }}</p>
                </div>
                <!-- <v-card
                    v-if="source_card"
                    class=""
                    max-width="344"
                    outlined
                  >
                    <v-list-item three-line >
                      <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{ source_card.brand }}
                        </div>
                        <v-list-item-title class="text-h5 mb-1 text-center">
                          **** **** **** {{ source_card.last4 }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ source_card.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    
                </v-card> -->
                <v-btn 
                  color="primary" 
                  class="mt-8 no-caps" 
                  rounded
                  x-large 
                  depressed 
                  @click="openUpdateCreditCard" 
                  >
                  {{ $t('update_credit_card') }}
                </v-btn>
                <!-- <div class="mt-4 mt-sm-8">
                  <v-row no-gutters>
                    <v-col cols="12" lg="6">
                      <div class="mr-lg-4">
                        <vue-paycard class="ml-lg-0" :value-fields="valueFields" :input-fields="inputFields" :set-type="valueFields.cardType"/>
                        <v-btn 
                          v-if="subscriptionSettings.stripe_customer_id && !state_update_credit_card"
                          color="primary" 
                          class="mt-8 no-caps" 
                          rounded
                          x-large 
                          depressed 
                          :loading="loading_credit_card" 
                          @click="updateCreditCard" 
                          >
                          {{ $t('update_credit_card') }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col v-if="state_update_credit_card" cols="12" lg="6" class="mt-4 mt-sm-8 mt-lg-0">
                      <v-card
                        class="ml-lg-4 pa-4 pa-sm-8"
                        outlined
                      >
                        <v-card-text class="pa-0">
                          <v-row>
                            <v-col cols="12">
                              <x-text-field
                                @input="formatCardNumber(valueFields.cardNumber)"
                                v-model="valueFields.cardNumber"
                                :label="labels.cardNumber"
                                :data-card-field="inputFields.cardNumber"
                                hide-details="auto"
                              ></x-text-field>
                            </v-col>
                            <v-col cols="12">
                              <x-text-field
                                v-model="valueFields.cardName"
                                :label="labels.cardName"
                                :data-card-field="inputFields.cardName"
                                hide-details="auto"
                              ></x-text-field>
                            </v-col>
                            <v-col cols="6" sm="4">
                              <x-select
                                :items="itemsCardMonth"
                                v-model="valueFields.cardMonth"
                                :label="labels.cardMonth"
                                :data-card-field="inputFields.cardMonth"
                                hide-details="auto"
                              ></x-select>
                            </v-col>
                            <v-col cols="6" sm="4">
                              <x-select
                                :items="itemsCardYear"
                                v-model="valueFields.cardYear"
                                :label="labels.cardYear"
                                :data-card-field="inputFields.cardYear"
                                hide-details="auto"
                              ></x-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <x-text-field
                                type="number"
                                @input="formatCardCvv(valueFields.cardCvv)"
                                v-model="valueFields.cardCvv"
                                :label="labels.cardCvv"
                                :data-card-field="inputFields.cardCvv"
                                hide-details="auto"
                              ></x-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions class="pa-0 pt-5 pt-sm-10">
                          <v-btn 
                            v-if="subscriptionSettings.stripe_customer_id && state_update_credit_card"
                            color="" 
                            class="no-caps" 
                            text
                            rounded
                            x-large 
                            depressed 
                            :loading="loading_credit_card" 
                            @click="cancelCreditCard" 
                            >
                            {{ $t('cancel') }}
                          </v-btn>
                          <v-btn 
                            v-if="state_update_credit_card"
                            color="primary" 
                            class="grow no-caps" 
                            rounded
                            x-large 
                            depressed 
                            :loading="loading_credit_card" 
                            @click="SaveCreditCard" 
                            >
                            {{ $t('save_credit_card') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </div> -->
                
                <!-- <v-btn 
                      v-if="!subscriptionSettings.stripe_customer_id"
                      color="red darken-2 white--text mt-5" 
                      class="no-caps" 
                      x-large 
                      depressed 
                      :loading="loading_credit_card" 
                      @click="addCreditCard" 
                      >
                      {{ $t('add_credit_card') }}
                </v-btn> -->
                <!-- <v-btn 
                  v-if="state_update_credit_card"
                  color="green darken-2 white--text mt-10" 
                  class="no-caps" 
                  x-large 
                  depressed 
                  :loading="loading_credit_card" 
                  @click="SaveCreditCard" 
                  >
                  {{ $t('save_credit_card') }}
                </v-btn>
                <v-btn 
                  v-if="subscriptionSettings.stripe_customer_id && !state_update_credit_card"
                  color="red darken-2 white--text mt-10" 
                  class="no-caps" 
                  x-large 
                  depressed 
                  :loading="loading_credit_card" 
                  @click="updateCreditCard" 
                  >
                  {{ $t('update_credit_card') }}
                </v-btn>
                <v-btn 
                  v-if="subscriptionSettings.stripe_customer_id && state_update_credit_card"
                  color="red darken-2 white--text mt-10 ml-10" 
                  class="no-caps" 
                  x-large 
                  depressed 
                  :loading="loading_credit_card" 
                  @click="cancelCreditCard" 
                  >
                  {{ $t('cancel') }}
                </v-btn> -->
              </div>
            </v-card>
          </v-col>
          
          <v-col
            cols="12"
            lg="6"
          >
            <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mr-lg-4" elevation="0">
              <div class="text-h6">{{ $t('subscription') }}</div>
              <v-simple-table v-if="subscriptionSettings !== null && subscriptionSettings.package" class="mt-8">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('package') }}
                      </th>
                      <th class="text-center">
                        {{ $t('auto_verify_slip') }}
                      </th>
                      <th class="text-center">
                        {{ $t('purchase') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in subscriptionSettings.package"
                      :key="item.name"
                    >
                      <td>
                        <v-radio-group v-model="selected_subscription">
                          <v-radio :value="item.id" :label="item.name"/>
                        </v-radio-group>
                      </td>
                      <td class="text-right">{{ formatNumber(item.verify_slip) }}</td>
                      <td class="text-right">{{ formatCurrency(item.price,subscriptionSettings.currency) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div>
                <v-btn 
                  v-if="source_card"
                  color="primary" 
                  class="mt-8 no-caps" 
                  rounded
                  x-large 
                  depressed 
                  :loading="loading_subscription" 
                  :disabled="(package_now.id == selected_subscription || (package_next && package_next.id == selected_subscription)) ? true : false" 
                  @click="Subscription" 
                  >
                  {{ $t('subscription') }}
                </v-btn>
              </div>
            </v-card>
          </v-col>
          
          <v-col
            cols="12"
            lg="6"
            class="mt-4 mt-sm-8 mt-lg-0"
          >
            <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 ml-lg-4" elevation="0">
              <div class="text-h6">{{ $t('additional_verify_slip') }} <span v-if="package_now">({{ package_now.name }})</span></div>
              <v-simple-table v-if="subscriptionSettings !== null && additionalVerifySlip" class="mt-8">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t('package') }}
                      </th>
                      <th class="text-center">
                        {{ $t('auto_verify_slip') }}
                      </th>
                      <th class="text-center">
                        {{ $t('purchase') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in additionalVerifySlip"
                      :key="item.id"
                    >
                      <td>
                        <v-radio-group v-model="selected_additional">
                          <v-radio :value="item.id" :label="item.name"/>
                        </v-radio-group>
                      </td>
                      <td class="text-right">{{ formatNumber(item.verify_slip) }}</td>
                      <td class="text-right">{{ formatCurrency(item.price,subscriptionSettings.currency) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div>
                <v-btn 
                  v-if="source_card"
                  color="primary" 
                  class="mt-8 no-caps" 
                  rounded
                  x-large 
                  depressed 
                  :loading="loading_additional" 
                  @click="buyAdditional" 
                  >
                  {{ $t('buy') }}
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

    </v-container>
  </div>
</template>
<script>
// import { VuePaycard } from "vue-paycard";
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'
export default {
  // components: {
  //   VuePaycard,
  //   //MenuBusinessSetting
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    state_update_credit_card: true,
    loading: true,
    loading_main: true,
    loading_additional: false,
    loading_subscription: false,
    disabled_subscription: false,
    loading_credit_card: false,
    subscriptionSettings: null,
    additionalVerifySlip: null,
    paymentProcessed: false,
    paymentProcessedGateway: null,
    subscriptionCancelled: false,
    subscriptionForm: {
      loading: false,
      months: null
    },
    stripeHandler: null,
    selectedMonths: null,
    selectedRemotePlanId: null,
    package_now: null,
    package_next: null, 
    selected_subscription: null,
    selected_additional: null,
    source_card: null,
    verify_slip_used: 0,
    verify_slip: 0,
    font_color: 'font-green',
    itemsCardMonth: [1,2,3,4,5,6,7,8,9,10,11,12],
    itemsCardYear: [],
    valueFields: {
        cardType: "",
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
    },
    inputFields : {
      cardNumber : "v-card-number",
      cardName : "v-card-name",
      cardMonth : "v-card-month",
      cardYear : "v-card-year",
      cardCvv : "v-card-cvv"
    },
    labels : {
      cardNumber : "Card Number",
      cardName : "Full Name",
      cardHolder : "Card Holder",
      cardMonth : "MM",
      cardYear : "YY",
      cardExpires : "Expires",
      cardCvv : "CVV"
    }
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    // Tab images
    this.tabImgs = [
      this.$init.config.schemeAndHost + '/img/business-transactions-tab.jpg'
    ]

    // Set image depending on the size of the browser window
    if (window.innerWidth > 960) {
      this.tabImg = this.tabImgs[0]
      this.tabImgHeight = this.tabImgDefaultHeight
      this.tabImgAspectRation = 1.7778
    } else {
      this.tabImgHeight = null
      this.tabImgAspectRation = 3
    }

    // Preload
    let images = new Array()
    this.tabImgs.forEach(function(img, i) {
      images[i] = new Image()
      images[i].src = img
    })

    //console.log(this.moment().format('YYYY'))
    for(let i = 0; i <= 10; i++){
      let year = parseInt(this.moment().format('YYYY'))
      this.itemsCardYear.push(year+i)
    }
    this.labels.cardName = this.$t('name')
    this.labels.cardHolder = this.$t('name')
    this.labels.expires = this.$t('expires')
    //let uuid = this.$auth.user().business_uuid ? this.$auth.user().business_uuid : this.$auth.user().uuid
    var channel = this.$pusher.subscribe(this.$auth.user().uuid);
    //console.log(channel)
    channel.bind('webhook_stripe_subscription', (res_pusher) => {
      //console.log(res_pusher)
      this.package_now = this.subscriptionSettings.package.find((e) => e.id == res_pusher.subscription.package_id)
      this.additionalVerifySlip = this.package_now.additional_verify_slip
      this.selected_subscription = this.package_now.id
      this.selected_additional = this.additionalVerifySlip[0].id
      this.verify_slip = res_pusher.subscription.verify_slip
      if(res_pusher.pending_subscription){
        this.package_next = this.subscriptionSettings.package.find((e) => e.id == res_pusher.pending_subscription.package_id)
      }
      this.$root.$snackbar(this.$t('subscription_update'))
    });
    this.loading_main = true
    this.axios
      .get('/business/subscription/settings', { params: { locale: this.$i18n.locale }})
      .then(response => {
        this.subscriptionSettings = response.data
        this.loading = false
        this.loading_main = false
        if(this.subscriptionSettings.subscription){
          //console.log(this.subscriptionSettings)
          this.package_now = this.subscriptionSettings.package.find((e) => e.id == this.subscriptionSettings.subscription.package_id)
          this.additionalVerifySlip = this.package_now.additional_verify_slip
          this.selected_subscription = this.package_now.id
          this.selected_additional = this.additionalVerifySlip[0].id
          if(this.subscriptionSettings.pending_subscription){
            this.package_next = this.subscriptionSettings.package.find((e) => e.id == this.subscriptionSettings.pending_subscription.package_id)
          }
          this.verify_slip_used = this.subscriptionSettings.subscription.verify_slip_used
          this.verify_slip = this.subscriptionSettings.subscription.verify_slip
          if(this.verify_slip_used >= this.verify_slip){
            this.font_color = 'font-red'
          }
        }
        else{
          this.package_now = this.subscriptionSettings.package[0]
          this.additionalVerifySlip = this.package_now.additional_verify_slip
          this.selected_subscription = this.package_now.id
          this.selected_additional = this.additionalVerifySlip[0].id
        }
        if(this.subscriptionSettings.stripe_customer_id && this.subscriptionSettings.source){
          this.state_update_credit_card = false
          this.source_card = this.subscriptionSettings.source
          this.valueFields.cardNumber = '**** **** **** ' + this.source_card.last4
          this.valueFields.cardName = this.source_card.name
          this.valueFields.cardMonth = this.source_card.exp_month
          this.valueFields.cardYear = this.source_card.exp_year
          this.valueFields.cardType = this.source_card.brand
        }
        /*
        if (this.subscriptionSettings.gateway == 'stripe') {
          
          let JavaScript = {
            load: function(src, callback) {
              let script = document.createElement('script'),
                  loaded
              script.setAttribute('src', src)
              if (callback) {
                script.onreadystatechange = script.onload = function() {
                  if (!loaded) {
                    callback()
                  }
                  loaded = true
                }
              }
              document.getElementsByTagName('head')[0].appendChild(script)
            }
          }

          let that = this
          
          JavaScript.load("//checkout.stripe.com/checkout.js", function() {
            that.stripeHandler = window.StripeCheckout.configure({
              key: that.subscriptionSettings.key,
              image: null,
              locale: 'auto',
              token: function(token) {
                //console.log(token)
                // You can access the token ID with `token.id`.
                that.loading_credit_card = true
                that.subscriptionForm.loading = true
                // Get the token ID to your server-side code for use.
                let url = that.subscriptionSettings.stripe_customer_id ? '/business/update/creditcard' : '/business/create/creditcard'
                that.axios.post(url, {
                  token: token.id, 
                  email: token.email, 
                  type: token.type, 
                })
                .then(function (response) {
                  that.paymentProcessed = true
                  that.paymentProcessedGateway = 'stripe'
                  that.subscriptionForm.loading = false
                  that.loading_credit_card = false
                  if(response.data.source){
                    that.source_card = response.data.source
                    that.valueFields.cardNumber = '**** **** **** ' + that.source_card.last4
                    that.valueFields.cardName = that.source_card.name
                    that.valueFields.cardMonth = that.source_card.exp_month
                    that.valueFields.cardYear = that.source_card.exp_year
                    that.valueFields.cardType = that.source_card.brand
                  }
                  if(response.data.customer){
                    that.subscriptionSettings.stripe_customer_id = response.data.customer.id
                  }
                })
                .catch(function (error) {
                  that.$root.$snackbar("An unknow error has occured. Please refresh this page and try again. Contact us if the error persists.")
                  that.subscriptionForm.loading = false
                  that.loading_credit_card = false
                  console.log(error)
                })
              }
            })
          })
        }
        */
      })
  },
  methods: {
    formatCardNumber(value){
      if(value){
        this.valueFields.cardNumber = value.replace(/ /g,'');
        this.valueFields.cardNumber = this.valueFields.cardNumber.match(/.{1,4}/g).join(' ')
      }
    }, 
    formatCardCvv(value){
      if(value && value.length > 3){
        this.valueFields.cardCvv = value.slice(0, 3)
      }
    }, 
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    openUpdateCreditCard () {
      this.$root.$UpdateCreditCard(this.valueFields,this.labels)
      .then((confirm) => {
        if (confirm.confirm) {
          //console.log(confirm)
          this.state_update_credit_card = false
          if(confirm.data.source){
            this.subscriptionSettings.source = confirm.data.source
            this.source_card = confirm.data.source
            this.valueFields.cardNumber = '**** **** **** ' + this.source_card.last4
            this.valueFields.cardName = this.source_card.name
            this.valueFields.cardMonth = this.source_card.exp_month
            this.valueFields.cardYear = this.source_card.exp_year
            this.valueFields.cardType = this.source_card.brand
          }
          if(confirm.data.customer){
            this.subscriptionSettings.stripe_customer_id = confirm.data.customer.id
          }
        }
      })
    },
    Subscription () {
      //console.log(this.selected_subscription)
      let data_package = this.subscriptionSettings.package.find((e) => e.id == this.selected_subscription)
      //console.log(data_package)
      if(!data_package){
        this.$root.$snackbar(this.$t('not_found'))
        return false
      }
      this.$root.$confirm(this.$t('confirm'), this.$t('confirm_subscription'),{
        color: 'primary',
        width: 360,
        zIndex: 200
      })
      .then((confirm) => {
        if (confirm.confirm) {
          this.loading_subscription = true
          this.axios.post('/business/subscription', {
              package_id: data_package.id,
          })
          .then(res => {
            this.loading_subscription = false
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.package_now = this.subscriptionSettings.package.find((e) => e.id == res.data.subscription.package_id)
              this.additionalVerifySlip = this.package_now.additional_verify_slip
              this.selected_subscription = this.package_now.id
              this.selected_additional = this.additionalVerifySlip[0].id
              if(res.data.pending_subscription){
                this.package_next = this.subscriptionSettings.package.find((e) => e.id == res.data.pending_subscription.package_id)
              }
              // if(this.package_now.id == 1){
              //   this.$root.$snackbar(this.$t('subscription_update'))
              // }
            }
          })
          .catch(err => {
            this.loading_subscription = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.error)
            }
          })
        }
      })
    },
    buyAdditional () {
      let data_package = this.subscriptionSettings.package.find((e) => e.id == this.selected_subscription)
      //console.log(data_package)
      if(!data_package){
        this.$root.$snackbar(this.$t('not_found'))
        return false
      }
      this.$root.$confirm(this.$t('confirm'), this.$t('confirm_buy_additional_verify'),{
        color: 'primary',
        width: 360,
        zIndex: 200
      })
      .then((confirm) => {
        if (confirm.confirm) {
          this.loading_additional = true
          this.axios.post('/business/additional_verify', {
            additional_verify_slip_id: this.selected_additional,
            package_id: data_package.id,
          })
          .then(res => {
            this.loading_additional = false
            if (res.data.status === 'success') {
              this.verify_slip = res.data.verify_slip
              this.$root.$snackbar(this.$t('buy_additional_verify_slip_successfully'))
            }
          })
          .catch(err => {
            this.loading_additional = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.error)
            }
            else{
              this.$root.$snackbar(err.response.data.message)
            }
          })
        }
      })
    },
    addCreditCard () {
      this.stripeHandler.open({
        name: this.$t('add_credit_card'),
        description: null,
        currency: this.subscriptionSettings.currency,
        amount: 0
      })
    },
    updateCreditCard () {
      // this.stripeHandler.open({
      //   name: this.$t('update_credit_card'),
      //   description: null,
      //   currency: this.subscriptionSettings.currency,
      //   amount: 0
      // })
      this.state_update_credit_card = true
    },
    cancelCreditCard () {
      this.state_update_credit_card = false
    },
    SaveCreditCard () {
      this.loading_credit_card = true
      this.axios.post('/business/save/creditcard', {
        name: this.valueFields.cardName,
        number: this.valueFields.cardNumber,
        exp_month: this.valueFields.cardMonth,
        exp_year: this.valueFields.cardYear,
        cvc: this.valueFields.cardCvv,
      })
      .then(res => {
        this.loading_credit_card = false
        if (res.data.status === 'success') {
          this.state_update_credit_card = false
          if(res.data.source){
            this.subscriptionSettings.source = res.data.source
            this.source_card = res.data.source
            this.valueFields.cardNumber = '**** **** **** ' + this.source_card.last4
            this.valueFields.cardName = this.source_card.name
            this.valueFields.cardMonth = this.source_card.exp_month
            this.valueFields.cardYear = this.source_card.exp_year
            this.valueFields.cardType = this.source_card.brand
          }
          if(res.data.customer){
            this.subscriptionSettings.stripe_customer_id = res.data.customer.id
          }
        }
      })
      .catch(err => {
        this.loading_credit_card = false
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.error)
        }
      })
    }
  }
}
</script>
<style scoped>
  .margin-top-0{
    margin-top: 0px!important;
  }
  .margin-right-10{
    margin-right: 10px;
  }
  .margin-left-10{
    margin-left: 10px;
  }
  .margin_0{
    margin: 0;
  }
  .font-red{
    color:#F44336;
  }
  .font-green{
    color:#4caf50;
  }
  .font-blue{
    color:#2196F3;
  }
 </style>
